import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { getBaseUrl } from 'src/utils'
import Header from 'src/components/Header'
import PageHelmet from 'src/components/PageHelmet'
import SingleAdvice from 'src/components/SingleAdvice'
import Footer from '../components/Footer'
import withLayout from 'src/components/withLayout'

class Advice extends Component {
  render() {
    const {
      locale,
      data: {
        advice: {
          title,
          titleColor,
          image,
          cards,
          descriptionNode: {
            childMarkdownRemark: { html: description },
          },
          descriptionColor,
          partnerLogo,
          partnerLogoBottom,
          slug,
        },
      },
      pageContext: { originalPath },
    } = this.props

    return (
      <>
        <PageHelmet title={title} url={`${getBaseUrl()}${locale}${originalPath}`} image={image.url} defer={false} />
        <Header full={true} locale={locale} />
        <SingleAdvice
          {...{
            title,
            titleColor,
            description,
            descriptionColor,
            partnerLogo,
            partnerLogoBottom,
            image,
            locale,
            cards,
            slug,
          }}
          locale={locale}
        />
        <Footer locale={locale} />
      </>
    )
  }
}

export const query = graphql`
  query Adivce($id: String!, $locale: String!) {
    advice: datoCmsAdvice(locale: { eq: $locale }, id: { eq: $id }) {
      title
      titleColor {
        red
        blue
        alpha
        green
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      descriptionColor {
        red
        blue
        alpha
        green
      }
      slug
      partnerLogo {
        url
        internal {
          contentDigest
        }
      }
      partnerLogoBottom {
        url
        internal {
          contentDigest
        }
      }
      image {
        id
        url
      }
      adviceCategory {
        title
      }
      cards {
        cardTitle
        id
        childDatoCmsTextNode {
          childMarkdownRemark {
            html
          }
        }
        buttonWithLinkAction
        buttonWithLinkActionText
        buttonWithLinkActionUrl

        buttonWithFormAction
        buttonWithFormActionName
        buttonWithFormActionText
      }
    }
  }
`

const customProps = {
  localeKey: 'advice2',
}

export default withLayout(customProps)(Advice)
