import React, { Component } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import Cards from './Cards'
import styles from './SingleAdvice.module.scss'

class SingleAdvice extends Component {
  state = {
    articleHeight: null,
    windowHeight: null,
    isFontSmall: false,
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState(
        {
          windowHeight: window.innerHeight,
          articleHeight: document.getElementById('article').offsetHeight,
        },
        () => (this.state.windowHeight < this.state.articleHeight ? this.setState({ isFontSmall: true }) : false),
      )
    }
  }

  render() {
    const {
      intl,
      title,
      titleColor,
      image,
      cards,
      description,
      descriptionColor,
      partnerLogo,
      partnerLogoBottom,
      locale,
      slug,
    } = this.props

    const { isFontSmall } = this.state

    return (
      <div className={styles.root}>
        <div className={styles.header} style={{ backgroundImage: `url(${image.url})` }}>
          <div className={styles.intro}>
            <h1
              className={styles.title}
              style={{
                color: `rgba(${titleColor ? titleColor.red : '255'}, ${titleColor ? titleColor.green : '255'}, ${
                  titleColor ? titleColor.blue : '255'
                }, ${titleColor ? titleColor.alpha : '255'})`,
              }}
            >
              {title}
            </h1>

            <div
              className={styles.subtitle}
              style={{
                color: `rgba(${descriptionColor ? descriptionColor.red : '255'}, ${
                  descriptionColor ? descriptionColor.green : '255'
                }, ${descriptionColor ? descriptionColor.blue : '255'}, ${
                  descriptionColor ? descriptionColor.alpha : '255'
                })`,
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <div className={styles.logos}>
            {partnerLogo && (
              <img className={styles.partnerLogo} src={partnerLogo.url} alt={partnerLogo.internal.contentDigest} />
            )}
            {partnerLogoBottom && (
              <img
                className={styles.partnerLogoBottom}
                src={partnerLogoBottom.url}
                alt={partnerLogoBottom.internal.contentDigest}
              />
            )}
          </div>
        </div>
        <div className={styles.inner}>
          <div className={classNames(styles.menu, isFontSmall && styles.smallDevice)} id="article">
            {cards.map(({ cardTitle, id }) => (
              <AnchorLink offset="92" className={styles.link} href={`#${id.toLowerCase()}`} key={id}>
                {cardTitle}
              </AnchorLink>
            ))}
          </div>
          <Cards
            {...{
              cards,
              intl,
              locale,
              title,
              slug,
            }}
          />
        </div>
      </div>
    )
  }
}

export default injectIntl(SingleAdvice)
