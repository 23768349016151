import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import SocialShare from 'src/components/SocialShare'
import { getBaseUrl } from 'src/utils'
import styles from './SingleAdvice.module.scss'
import submit from 'src/img/submit.svg'

class Cards extends Component {
  render() {
    const { cards, intl, locale, title, slug } = this.props

    return (
      <div className={styles.cards}>
        {cards && cards.map((item) => <Card data={item} intl={intl} key={item.id} />)}
        <Sharing {...{ title, locale, slug }} />
      </div>
    )
  }
}

class Sharing extends Component {
  state = {
    isModalOpen: false,
  }

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }

  render() {
    const { title, locale, slug } = this.props

    return (
      <button className={styles.buttonShare} onClick={this.toggleModal}>
        <span className={styles.buttonText}>
          <FormattedMessage id="home.share" />
        </span>
        <SocialShare
          className={styles.share}
          isWhite={true}
          title={title}
          url={`${getBaseUrl()}${locale}/advice/${slug}`}
          isModalOpen={this.state.isModalOpen}
          toggleModal={this.toggleModal}
        />
      </button>
    )
  }
}

class Card extends Component {
  state = {
    value: {},
    isModalVisible: false,
    isSubmitted: false,
    isModalOpen: false,
    articleHeight: null,
    windowHeight: null,
    isFontSmall: false,
  }

  closeModal = (e) => {
    if (e.target.className.search('formModalWrap') !== -1) {
      this.setState(
        {
          isModalVisible: !this.state.isModalVisible,
        },
        () => {
          if (typeof window !== 'undefined') {
            window.document.body.style.overflow = 'auto'
          }
        },
      )
    }
  }

  openModal = (e, id) => {
    e.preventDefault()
    this.setState(
      {
        isModalVisible: !this.state.isModalVisible,
      },
      () => {
        if (typeof window !== 'undefined' && this.state.isModalVisible) {
          window.document.body.style.overflow = 'hidden'
        }
        if (typeof window !== 'undefined' && !this.state.isModalVisible) {
          window.document.body.style.overflow = 'auto'
        }
      },
    )
  }

  encode = (data) => {
    return Object.keys(data)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&')
  }

  handleChangeInput = (e) => {
    this.setState({
      value: {
        ...this.state.value,
        [e.target.name]: e.target.value,
      },
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({
        'form-name': form.getAttribute('name'),
        ...this.state.value,
      }),
    })
      .then(() =>
        this.setState({
          isSubmitted: true,
        }),
      )
      .catch((error) => console.log(error))
  }

  render() {
    const {
      data: {
        cardTitle,
        childDatoCmsTextNode: {
          childMarkdownRemark: { html },
        },
        id,
        buttonWithLinkAction,
        buttonWithLinkActionText,
        buttonWithLinkActionUrl,
        buttonWithFormAction,
        buttonWithFormActionName,
        buttonWithFormActionText,
      },
      intl,
    } = this.props

    return (
      <section className={styles.card} id={`${id.toLowerCase()}`} key={id}>
        <h2 className={styles.cardTitle}>{cardTitle}</h2>
        <div className={styles.cardText} dangerouslySetInnerHTML={{ __html: html }} />
        {buttonWithLinkAction && (
          <a className={styles.cardButton} target="_blank" rel="noopener noreferrer" href={buttonWithLinkActionUrl}>
            {buttonWithLinkActionText}
          </a>
        )}

        {buttonWithFormAction && (
          <>
            <div
              className={styles.formModalWrap}
              onClick={this.closeModal}
              style={this.state.isModalVisible ? { display: 'flex' } : { display: 'none' }}
            >
              <div className={styles.formInner}>
                <form
                  className={styles.formModal}
                  style={!this.state.isSubmitted ? { display: 'flex' } : { display: 'none' }}
                  name={buttonWithFormActionName}
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.onSubmit}
                >
                  <button className={styles.buttonClose} onClick={this.openModal} />

                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value={buttonWithFormActionName} />
                  <input
                    onChange={(e) => this.handleChangeInput(e)}
                    required
                    type="text"
                    name="name"
                    placeholder={intl.formatMessage({ id: 'home.name' })}
                    className={styles.input}
                  />
                  <input
                    onChange={(e) => this.handleChangeInput(e)}
                    type="text"
                    name="phone"
                    placeholder={intl.formatMessage({ id: 'home.phone' })}
                    className={styles.input}
                  />
                  <textarea
                    onChange={(e) => this.handleChangeInput(e)}
                    type="text"
                    name="message"
                    placeholder={intl.formatMessage({ id: 'home.message' })}
                    className={styles.textarea}
                    rows="5"
                  />
                  <button className={styles.openModal} type="submit">
                    {buttonWithFormActionText}
                  </button>
                </form>
                <div
                  className={styles.successMessage}
                  style={this.state.isSubmitted ? { display: 'block' } : { display: 'none' }}
                >
                  <h2>{intl.formatMessage({ id: 'home.adviceFormSuccess' })}</h2>
                  <img src={submit} alt="check" />
                </div>
              </div>
            </div>
            <button onClick={(e, id) => this.openModal(e, id)} className={styles.openModal}>
              {buttonWithFormActionText}
            </button>
          </>
        )}
      </section>
    )
  }
}

export default Cards
